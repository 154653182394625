import React, { useState, useEffect } from 'react'
import axios from 'axios'
import '../css/LoginSignup.css'
import { useNavigate } from 'react-router-dom';
import devlog from '../utilities/Devlog.js';

const LoginForm = () => {
  const navigate = useNavigate();

    const [formData, setFormData] = useState({
      username: '',
      password: ''
    });
    const [error, setError] = useState()

  
    // Handle form field changes
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };


    // Handle form submission
    async function handleSubmit(e) {
      e.preventDefault();
      try {
        // devlog(`${process.env.REACT_APP_API_URL}`);
    
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/login`, {
              username: formData.username,
              password: formData.password
            }, {withCredentials:true});

            // console.log('login response:', response)

            if (response.data.token) {
                localStorage.setItem('token', response.data.token)
                // console.log('User logged in successfully!');
                navigate('/')
            }
            setError(response.data.message)
        } catch (e) {
            console.error('Login failed:', e);
        }
    };

    // useEffect(() => {
    //   console.log("error", error)
    // }, [error])
  
    return (
      <div>
        <div className="login--image-container">
            <img className="header--image" src="./sunrise-sky.avif" alt='Sky image background'/>
            <div className="login--form-container">
              <form onSubmit={handleSubmit}>
              <p className="login--main-text">Log In to Autopilot</p>
              <div className="form-items">
                  <div>
                  { error && <p className="form-error"> {error} </p> }
                    <label htmlFor="username">
                    <input
                      placeholder="Username"
                      className="text-box"
                      type="text"
                      id="username"
                      name="username"
                      value={formData.username}
                      onChange={handleChange}
                      required
                    />
                    </label>
                  </div>
                  <div>
                    <label htmlFor="password">
                    <input
                      placeholder="Password"
                      className="text-box"
                      type="password"
                      id="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                    </label>
                  </div>
              <button className="form--button" type="submit">log in</button>
              <p className="bottom-text"> No account yet? Sign up <a href={`${process.env.REACT_APP_CLIENT_URL}/signup`}> here </a></p>
            </div>
          </form>
        </div>                
    </div>
    </div>
    );
  };

  
  export default LoginForm;