import axios from 'axios'

// API function to search whole months for flights
async function MonthSearch({ departureId, destinationId, depDate, retDate, type, setOptions, numAdults, numInfants }){

    let options;
    // devlog("sanity check:", departureId, destinationId, depDate, retDate, type);

    if (type === 'Roundtrip') {
        options = {
            method: 'GET',
            url: 'https://sky-scanner3.p.rapidapi.com/flights/search-roundtrip',
            params: {
                fromEntityId: departureId,
                toEntityId: destinationId,
                wholeMonthDepart: depDate,
                wholeMonthReturn: retDate,
                adults: numAdults,
                infants: numInfants
            },
            headers: {
                'x-rapidapi-key': '7d2bbfcab8msh5ca0c5ea3ac9a0bp132895jsn445e4cb24826',
                'x-rapidapi-host': 'sky-scanner3.p.rapidapi.com'
            }
        }
    }

    if (type === 'Oneway') {
        options = {
            method: 'GET',
            url: 'https://sky-scanner3.p.rapidapi.com/flights/search-one-way',
            params: {
                fromEntityId: departureId,
                toEntityId: destinationId,
                wholeMonthDepart: depDate,
                adults: numAdults,
                infants: numInfants
            },
            headers: {
                'x-rapidapi-key': '7d2bbfcab8msh5ca0c5ea3ac9a0bp132895jsn445e4cb24826',
                'x-rapidapi-host': 'sky-scanner3.p.rapidapi.com'
            }
        }
    }
    try {
        const response = await axios.request(options)
        // check if response is successful, otherwise throw error
        if (setOptions){
            setOptions(options)
        }
        return response.data
    }
    catch (e) {
        console.error("Error in MonthSearch!:", e);
        // throw e;  // Re-throw error for handling in the calling function
    }

}

export default MonthSearch
