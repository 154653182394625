import React, { useState } from 'react'
import Roundtrip from './Roundtrip.js'
import SetAlertButton from './SetAlertButton.js'
import './../css/Alerts.css'
import devlog from '../utilities/Devlog.js';
import { GoArrowSwitch } from "react-icons/go";
import { IoIosArrowDown } from "react-icons/io";

export default function Alerts({ isLoggedIn }){
    const [type, setType] = React.useState('Roundtrip')

    const [selectedPrice, setSelectedPrice] = useState('10');
    const [evilError, setEvilError] = useState(null)
    const [isWholeMonth, setIsWholeMonth] = useState(false)     //checkbox
    const [numAdults, setNumAdults] = useState(1)
    const [numInfants, setNumInfants] = useState(0)
    const [cabinClass, setCabinClass] = useState("Economy")
    const [isCabinClass, setIsCabinClass] = useState(false)
    const [numStops, setNumStops] = useState("Stops (any)")
    const [isNumStopsDropdown, setIsNumStopsDropdown] = useState(false)

    const [monthPreferences, setMonthPreferences] = useState({
        checked: false,
        set: false,
        weekendTrips: true,
        threeFourDayTrips: false,
        oneWeekTrips: false,
        onePlusWeekTrips: false,
    })
    const [processedBuckets, setProcessedBuckets] = useState(null)
    const [isPeopleDropdown, setIsPeopleDropdown] = useState(false)

    function toggleTripType() {
        if (type == 'Roundtrip') {
            setType('Oneway')
        }
        else {
            setType('Roundtrip')
        }
    }

    function toggleDropdown() {
        if(isPeopleDropdown) {
            setIsPeopleDropdown(false)
        }
        else {
            setIsPeopleDropdown(true)
        }
    }

    function handleCabinClassChange(newClass) {
        setCabinClass(newClass)
        setIsCabinClass(false)
    }

    function subNumAdults(num) {
        if (numAdults > 0) setNumAdults(prev => prev + num)
    }
    function addNumAdults(num) {
        if (numAdults < 3) setNumAdults(prev => prev + num)
    }
    function subNumInfants(num) {
        if (numInfants > 0) setNumInfants(prev => prev + num)
    }
    function addNumInfants(num) {
        if (numInfants < 3) setNumInfants(prev => prev + num)
    }

    function handleStopsChange(val) {
        setNumStops(val)
        setIsNumStopsDropdown(false)
    }

    return(
        // display roundtrip or Oneway depending on radio button selection
        <div className="card--container">

            <div className="card">
                <div className="button-options">
                    <div className="option option-1"> <p>{type} <GoArrowSwitch className="toggle" onClick={toggleTripType}/></p></div>
                    <div className="option option-2" >
                        {numAdults}{" "}{numAdults > 1 ? <p>Adults</p> : <p>Adult</p>}
                        {numInfants > 0 && (
                            <div>
                            {numInfants} <p>Infants</p>
                            </div> 
                        )} <IoIosArrowDown onClick={toggleDropdown}/>

                        { isPeopleDropdown && 
                            <ul className="alerts--ul">
                                <li>Adults
                                    <button onClick={() => subNumAdults(-1)}>-</button>
                                    {numAdults}
                                    <button onClick={() => addNumAdults(1)}>+</button>
                                </li>
                                <li>Infants
                                    <button onClick={() => subNumInfants(-1)}>-</button>
                                    {numInfants}
                                    <button onClick={() => addNumInfants(1)}>+</button>
                                </li>
                            </ul>
                        }
            
                    </div>
                    <div className="option option-3">{cabinClass} <IoIosArrowDown onClick={()=> setIsCabinClass(prev => !prev)}/>
                        {isCabinClass &&
                            <ul className="alerts--ul"> 
                                <li onClick={()=> handleCabinClassChange("Economy")}>Economy</li>
                                <li onClick={()=> handleCabinClassChange("Premium Economy")}>Premium Economy</li>
                                <li onClick={()=> handleCabinClassChange("Business")}>Business</li>
                                <li onClick={()=> handleCabinClassChange("First")}>First</li>
                            </ul>
                        }
                    </div>

                    <div className="option option-4">{numStops} <IoIosArrowDown onClick={()=> setIsNumStopsDropdown(prev => !prev)}/>
                        {isNumStopsDropdown &&
                            <ul className="alerts--ul">
                                <li onClick={()=> handleStopsChange("Stops (any)")}>Any</li>
                                <li onClick={()=> handleStopsChange("Direct")}>Direct</li>
                                <li onClick={()=> handleStopsChange("1 stop")}>1 stop</li>
                                <li onClick={()=> handleStopsChange("2+ stops")}>2+ stops</li>
                            </ul>
                        }
                    </div>
                </div>
                <div>
                    <Roundtrip 
                        setEvilError={setEvilError}
                        evilError={evilError}
                        type={type}
                        monthPreferences={ monthPreferences }
                        setMonthPreferences={ setMonthPreferences }
                        processedBuckets={ processedBuckets }
                        setProcessedBuckets={ setProcessedBuckets }
                        isWholeMonth={ isWholeMonth }
                        setIsWholeMonth={ setIsWholeMonth }
                        selectedPrice={ selectedPrice }
                        setSelectedPrice= { setSelectedPrice }
                        isLoggedIn= { isLoggedIn }
                        numAdults={ numAdults }
                        numInfants={ numInfants }
                        cabinClass= {cabinClass }
                        numStops= { numStops }
                     /> 
                </div>


                {/* <SetAlertButton 
                    // price={selectedPrice} 
                    // options={options}
                    // isLoggedIn={isLoggedIn}
                    // evilError={evilError}
                    // monthPreferences={monthPreferences}
                    // processedBuckets={processedBuckets}
                    // isWholeMonth={ isWholeMonth }
                /> */}
                </div>

            </div>
    )


}