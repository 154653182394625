import React, { useState, useEffect } from 'react';
import axios from 'axios'
import './App.css';
import NavBar from './components/Navbar.js';
import Header from './components/Header.js'
import Alerts from './components/Alerts.js'
import SignupForm from './components/SignupForm.js';
import LoginForm from './components/LoginForm.js'
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import devlog from './utilities/Devlog.js';


function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false)

  devlog("app.js re-render")

  // useState(() => {
  //   console.log("isLoggedIn:", isLoggedIn)
  // }, [])



  return(
    <Router>
      <Routes>
        <Route path="/" element= {
          <div>
            <NavBar
              setIsLoggedIn={setIsLoggedIn}
            />
            <Header />
            <Alerts
              isLoggedIn={isLoggedIn}
            />
          </div>} />

        <Route path="/signup" element={
          <div>
            <NavBar
              setIsLoggedIn={setIsLoggedIn}
            />
            <SignupForm />
            </div>
          } />
        <Route path="/login" element={
          <div>
            <LoginForm/>
          </div>
          } />
      </Routes>
    </Router>

  )

}


export default App;

       
