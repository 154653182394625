import React, { useEffect, useState, useCallback, useRef } from 'react'
import devlog from '../utilities/Devlog.js';
import ReactDatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import MonthSearch from '../utilities/MonthSearch.js';
import ExactDateSearch from '../utilities/ExactDateSearch.js';
import FetchIncompleteDates from '../utilities/FetchIncompleteDates.js'
// import AccuracyTest from '../utilities/AccuracyTest.js';
import MonthCheckboxes from './MonthCheckboxes.js';
import InfoCard from './InfoCard.js'
import debounce from 'debounce'
// import './../css/TripType.css'
import '../css/FlightSearchRed.css'
import { FaPlaneDeparture } from "react-icons/fa";
import { FaPlaneArrival } from "react-icons/fa";
import { FaCalendarDay } from "react-icons/fa";
import { IoPricetag } from "react-icons/io5";
import SetAlertButton from './SetAlertButton.js';



export default function Roundtrip({ 
    setEvilError, evilError, type, monthPreferences, setMonthPreferences, processedBuckets, setProcessedBuckets,
    isWholeMonth, setIsWholeMonth, isLoggedIn, numAdults, numInfants, cabinClass, numStops}){

    const DatePicker = ReactDatePicker.default ?? ReactDatePicker;
    

    const [selectedPrice, setSelectedPrice] = useState('')
    const [loading, setLoading] = useState(false)
    const [options, setOptions]= useState('')
    const [completed, setCompleted] = useState(false)
    const [avgPrice, setAvgPrice] = useState(null)
    const [minPrice, setMinPrice] = useState(null)

    const [userInput, setUserInput] = useState({
        departure: "",
        destination: "",
        depOrig: null,
        depFormatted: "",
        retOrig: null,
        retFormatted: ""
    })
    const [autoDepartureArray, setAutoDepartureArray] = useState([])
    const [depText, setDepText] = useState(null)
    const [autoDestinationArray, setAutoDestinationArray] = useState([])
    const [desText, setDesText] = useState(null)

    const dropdownRefDeparture = useRef(null)
    const dropdownRefDestination = useRef(null)
    const depTextRef = useRef(depText);
    const desTextRef = useRef(desText)


    // useEffect(() => {
    //     devlog("autoarray:", autoDestinationArray)
    // }, [autoDestinationArray])

    const [results, setResults] = useState(null)
    const [buckets, setBuckets] = useState(null)

    useEffect(() => {
        // devlog("depText in useEffect:", depText)
        depTextRef.current = depText;
    }, [depText])

    useEffect(() => {
        // devlog("desText in useEffect:", desText)
        desTextRef.current = desText;
    }, [desText])

    const [departureId, setDepartureId] = useState(null)
    const [destinationId, setDestinationId] = useState(null)

    // holds 5 best flights returned from roundtrip API call
    const [flightInfo, setFlightInfo] = useState([])
    const [cheapestFlights, setCheapestFlights] = useState([])
    const [lilysCheapestFlights, setLilysCheapestFlights] = useState([])

    useEffect(() => {
        setUserInput({
            departure: "",
            destination: "",
            depOrig: null,
            depFormatted: "",
            retOrig: null,
            retFormatted: ""
        });
        setFlightInfo([]);
        // devlog("flight info length", flightInfo.length);
        setLoading(false)
    }, [type])

    // baseurls
    let autoBaseURL = `https://sky-scanner3.p.rapidapi.com/flights/auto-complete?query=`;
    
    // function to close dropdown menu on outside click
    const handleClickOutside = (e) => {
        // devlog("depTextRef.current:", depTextRef.current)
        // devlog("desTextRef.current:", desTextRef.current)
        // devlog("e.target", e.target)
        // devlog("dropdownRefDeparture contains target:", dropdownRefDeparture.current.contains(e.target))

        if (dropdownRefDeparture.current && !dropdownRefDeparture.current.contains(e.target)) {  // if there is a DOM element as reference, and it's an outside click
            devlog("about to clear in handleClickOutside")
            setAutoDepartureArray([])

            if (!depTextRef.current) {
                setUserInput(prev => ({
                    ...prev,
                    departure: '',
                }))
            }
        }
        if (dropdownRefDestination.current && !dropdownRefDestination.current.contains(e.target)) {
            setAutoDestinationArray([])
            if (!desTextRef.current) {
                setUserInput(prev => ({
                    ...prev,
                    destination: '',
                }))
            }
        }
    }


    // event listener to detect outside clicks and call dropdown close method
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    // updates departure and destination city as client types it in
    function handleChange(e){
        const { name, value } = e.target
        setUserInput(prevInfo => 
            ({...prevInfo, [name]: value}))
    }

    /* sets flight date in orig format (datetime string) for calendar, and also a formatted version for the roundtrip API call
    bug in which if current date is used it is never formatted for the API call
    also apply a default minimum date for return, and make sure departure < return */
    function handleDepDate(date) {
        var formattedDate = formatDate(date)
        setUserInput(prev => ({...prev, depOrig: date, depFormatted:formattedDate}))
        setEvilError(null)
    }
    
    function handleRetDate(date) {
        if (!userInput.depOrig) {
            setEvilError("Please enter a departure date first.")
            return;
        }
        var formattedDate = formatDate(date)
        setUserInput(prev => ({...prev, retOrig: date, retFormatted:formattedDate}))
    }

    useEffect(() => {
        // devlog("DEP TEXT ACTUALLY SET- RACE CONDITION?", depText)
    }, [depText])

    function handleDepartureClick(item) {
        // devlog("DEPARTURE ID AND DEP TEXT SET")
        // devlog("userInput.departure:", userInput.departure)
        setDepartureId(item.presentation.id)
        setDepText(item.presentation.skyId)
        // devlog("userInput.departure:", userInput.departure)
        setAutoDepartureArray([]) // close dropdown on selection

    }

    // useEffect(() => {
    //     devlog("userInput.departure useEffect:", userInput.departure)
    // }, [userInput.departure])

    function handleDestinationClick(item) {
        // devlog("DEPARTURE ID AND DES TEXT SET")
        setDestinationId(item.presentation.id)
        setDesText(item.presentation.skyId)
        setAutoDestinationArray([])
    }

    function formatDate(date) {
        // convert date from Date object to string with correct format for flight search call
        // if "search whole month" is checked, return in format 'YYYY-MM'
        // else, for exact dates, return in format 'YYYY-MM-DD'
        let formattedDate = ''
        if (isWholeMonth) {
            formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`
            // devlog("whole month formattedDate:", formattedDate);
        }
        else {
            formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
            // devlog("exact date formattedDate:", formattedDate);
        }
        return formattedDate
    }

    useEffect(() => {
        // devlog("DEPTEXT SET TO NULL")
        setDepText(null)
    }, [userInput.departure])

    useEffect(() => {
        setDesText(null)
    }, [userInput.destination])




    // when departure or destination field is changed, make a debounced call to autocomplete to *display* airport options to user
    const debouncedAutocompleteCall = useCallback(
        debounce(async () => {
            // devlog("autocomplete call about to be made")
            setAutoDepartureArray([])
            await DepartureAutocompleteCall();
        }, 1000), // Adjust the debounce delay as needed
        [userInput.departure]
    );

    useEffect(() => {
        debouncedAutocompleteCall();
        // Cleanup on unmount
        return () => {
          debouncedAutocompleteCall.clear();
        };
      }, [userInput.departure, debouncedAutocompleteCall]);



    const debouncedDesAutocompleteCall = useCallback(
        debounce(async () => {
            // devlog("autocomplete call about to be made")
            await DestinationAutocompleteCall();
        }, 500), // Adjust the debounce delay as needed
        [userInput.destination]
    );
    
      useEffect(() => {
        debouncedDesAutocompleteCall();
        // Cleanup on unmount
        return () => {
          debouncedDesAutocompleteCall.clear();
        };
      }, [userInput.destination, debouncedDesAutocompleteCall]);


    /* calls flight searches, ONLY if departureid and destinationid have been fetched from autocomplete API and dates correctly formatted */
    useEffect(() => {
        if (departureId && destinationId) {
            if (isWholeMonth) {
                if (userInput.depFormatted && userInput.retFormatted) {
                    // devlog("Month Search Call started");
                    MonthSearchCall();
                }
            }
            else if (type === "Roundtrip") {
                if (userInput.depFormatted && userInput.retFormatted) {
                    // devlog("Roundtrip Call started");
                    RoundtripCall();
                }
            }
            else if (type === "Oneway") {
                if (userInput.depFormatted){
                    RoundtripCall()
                }
            }
            else {
                // devlog("unexpected trip type in useEffect");
            }
        }
    }, [departureId, destinationId, userInput.depFormatted, userInput.retFormatted])

    useEffect(() => {
        // devlog("departureId", departureId)
        // devlog("destinationId", destinationId)

    }, [departureId, destinationId])

 
    /* autocomplete call m*/
    async function DepartureAutocompleteCall() {
        // devlog("IN DEPARTURE AUTOCOMPLETE")
        try {
            // setAutoDepartureArray([])

            const options = {
                method: 'GET',
                headers: {
                  'x-rapidapi-key': '7d2bbfcab8msh5ca0c5ea3ac9a0bp132895jsn445e4cb24826',
                  'x-rapidapi-host': 'sky-scanner3.p.rapidapi.com'
                }
            }

            const departureURL = autoBaseURL + userInput.departure;
            // devlog("userInput.departure on autocomplete:", userInput.departure);
            // devlog("userInput.destination on autocomplete:", userInput.destination);
    
            const departureResponse = await fetch(departureURL, options);
            const departureData = await departureResponse.json();

            if (departureData.data){
                departureData.data.forEach(location => {
                    setAutoDepartureArray( prevItems => [ ...prevItems, location])
                })
            }

        } catch (e) {
            console.error("error in autcomplete call", e)
            setEvilError("Could not fetch flight info, please try again")
            setLoading(false)
        }
    }
        async function DestinationAutocompleteCall() {
            // devlog("IN DESTINATION AUTOCOMPLETE")
            try {
                setAutoDestinationArray([])
    
                const options = {
                    method: 'GET',
                    headers: {
                      'x-rapidapi-key': '7d2bbfcab8msh5ca0c5ea3ac9a0bp132895jsn445e4cb24826',
                      'x-rapidapi-host': 'sky-scanner3.p.rapidapi.com'
                    }
                }
    
                const destinationURL = autoBaseURL + userInput.destination;
                // devlog("userInput.deestination on autocomplete:", userInput.destination);
        
                const destinationResponse = await fetch(destinationURL, options);
                const destinationData = await destinationResponse.json();
    
                if (destinationData.data) {                
                    destinationData.data.forEach(location => {
                    setAutoDestinationArray( prevItems => [ ...prevItems, location])
                })}
    
            } catch (e) {
                console.error("error in autcomplete call", e)
                setEvilError("Could not fetch flight info, please try again")
                setLoading(false)
            }
        }
    

    async function MonthSearchCall() {
        setEvilError(null)
        // devlog("MonthSearch call!");

        if (isWholeMonth) {
            try {
                // devlog(':3');
                const monthResponse = await MonthSearch({ departureId, destinationId, 
                    depDate: userInput.depFormatted, retDate: userInput.retFormatted, 
                    type, setOptions,
                    numAdults, numInfants })

                // if message: okay, set month data
                setResults(monthResponse.data.flightQuotes.results)
                setBuckets(monthResponse.data.flightQuotes.buckets)

                // await AccuracyTest();

                // if empty, display no flights found for your query error
                // if message: not okay, set error 
            }
            catch (e) {
                // devlog(e);
            }
        }
    }

    function MapResultsToBuckets() {
        let resMap = {};

        // devlog("sanity check: map results to buckets function");
        results.forEach((res => {
            resMap[res.id] = res
        }))
        // return new buckets array which contains
        // contents of each bucket plus new field which is:
        // array "results" which contains all of the results in "results" that correspond to that bucket
        return buckets.map(bucket => {
            const bucketResults = bucket.resultIds.map(resultId => resMap[resultId])
            return {
                ...bucket,
                results: bucketResults
            }
        })
    }

    useEffect(() => {
        // devlog("sanity check results:", results);
        if (results) {
            // devlog(results.length);

            results.forEach(res => res.content.outboundLeg.localDepartureDate)
        }
    }, [results])

    
    useEffect(() => {
        // devlog("sanity check processed buckets:", processedBuckets);
    }, [processedBuckets])

    useEffect(() => {
        if (buckets && results) {
            const _processedBuckets = MapResultsToBuckets();
            setProcessedBuckets(_processedBuckets)
        }
    }, [results, buckets])



    async function RoundtripCall(){

        try {
            setEvilError(null)
            setLoading(true)

            devlog("numStops:", numStops)

            const flightData = await ExactDateSearch({
                departureId, destinationId,
                depDate: userInput.depFormatted, retDate: userInput.retFormatted,
                type,
                setOptions,
                numAdults, numInfants,
                cabinClass,
                numStops
            })

            // console.table("FLIGHT DATA: (EXACT SEARCH)", flightData)

            const aggregatedData = await FetchIncompleteDates({ flightData, numStops })

            // console.table("AGGREGATED DATA: (INCOMPLETE DATE SEARCH" , aggregatedData)

            const removeDuplicates = arr => {
                const seen = new Set();
                return arr.filter((item) => {
                    const key = `${item.price.formatted}-${item.legs[0].departure}-${item.legs[0].arrival}`
                    if (seen.has(key)) {
                        return false
                    }

                    seen.add(key)
                    return true;
                })
            }

            if (aggregatedData.length > 0) {
                setLoading(false)
                const cleanedAggregatedData = removeDuplicates(aggregatedData)
                // console.table(aggregatedData)kk
                setFlightInfo(cleanedAggregatedData.slice(0, 10));
            }
            if (aggregatedData.length > 0) {
                const cheapestData = aggregatedData.filter(item =>
                    item.tags && item.tags.some(tag => tag.includes("cheapest"))
                  );
                setCheapestFlights(cheapestData.slice(0,20))
            }

            if (aggregatedData.length > 0) {
                const lilysCheapest = [...aggregatedData].sort((a, b) => a.price.raw - b.price.raw);
                setLilysCheapestFlights(lilysCheapest.slice(0,20))
            }
        } catch (e) {
            console.error(e)
            setLoading(false)
            setEvilError("Could not fetch flight information, please try again.")
        }
    }


    /* calculates min + average price of 5 best flights and sets state */
    React.useEffect(() => {
        // calculate info to be displayed
        let newAvgPrice = flightInfo.reduce((total, item) => total + item.price.raw, 0) / flightInfo.length;
        newAvgPrice = Number(newAvgPrice.toFixed(2)); // Round to 2 decimal placess
        const newMinPrice = flightInfo.reduce((minPrice, item) => {
            return item.price.raw < minPrice ? item.price.raw : minPrice;
        }, Infinity);
        setMinPrice(newMinPrice)
        setAvgPrice(newAvgPrice);
        }, [flightInfo]);


    /* used to determine minimum date for calendar */
    function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    useEffect(() => {
        // // devlog("evil error", evilerror);
    }, [evilError])



    return(
        <div>
            { evilError && <p className="evil-error">{evilError} </p> }
            <form>

                <div className="form-container">
                <label>
                    <div className="wrapper destination">
                        <FaPlaneDeparture className="icon" />
                        <input
                            className="input-box"
                            type="text"
                            placeholder="Departure city"
                            name="departure"
                            value={depText ? depText : userInput.departure}
                            onChange={handleChange}
                            required={true}
                            autoComplete="off"
                        />
                        <div ref={dropdownRefDeparture}>
                            { !depText && userInput.departure && (
                                <ul className="flightsearch--ul">
                                    <li>Loading...</li>
                                </ul>
                            )}
                            { autoDepartureArray.length > 0 && (
                                <ul className="flightsearch--ul">
                                { autoDepartureArray.map((item, index) => {
                                    return <li 
                                            key={index}
                                            onClick={() => handleDepartureClick(item)}> { item.presentation.suggestionTitle }
                                        </li>
                                })}
                                </ul>
                            )}
                        </div>
                    </div>
                </label>
                <label>
                    <div className="wrapper arrival">
                    <FaPlaneArrival className="icon" />
                        <input
                            className="input-box"
                            type="text"
                            placeholder="Arrival city"
                            name="destination"
                            value= { desText ? desText : userInput.destination }
                            onChange={handleChange}
                            required={true}
                            autoComplete="off"
                        />

                        <div ref={dropdownRefDestination}>
                            { (!desText && userInput.destination) && (
                                <ul className="flightsearch--ul">
                                    <li>Loading...</li>
                                </ul>
                            )}
                            { autoDestinationArray.length > 0 && (
                                <ul className="flightsearch--ul"> 
                                { autoDestinationArray.map((item, index) => {
                                    return <li 
                                            key={index}
                                            onClick={() => handleDestinationClick(item)}> { item.presentation.suggestionTitle } </li>
                                })}
                                </ul>
                            )} 
                        </div>
                    </div>
                </label>
                    <label>
                        <div className="wrapper">
                            <FaCalendarDay className="icon" />
                            <DatePicker
                                className="react-datepicker"
                                placeholderText= { isWholeMonth ? "Departure month" : "Departure date" }
                                selected={userInput.depOrig}
                                onChange={(date) => handleDepDate(date)}
                                minDate={ addDays(new Date(), 1)}
                                showMonthYearPicker = { isWholeMonth }
                                showTwoColumnMonthYearPicker={ isWholeMonth }
                                dateFormat= { isWholeMonth ? "MM/yyyy" : 'MM/dd/yyyy' }
                                />
                        </div>
                    </label>
                { type === 'Roundtrip' &&
                    <label>
                        <div className="wrapper">
                        <FaCalendarDay className="icon"/>
                            <DatePicker 
                                className="react-datepicker"
                                placeholderText={ isWholeMonth ? "Return month" : "Return date" }
                                selected={userInput.retOrig} 
                                onChange={(date) => handleRetDate(date)}
                                minDate={ isWholeMonth ? userInput.depOrig : addDays(userInput.depOrig, 1 )}
                                showMonthYearPicker = { isWholeMonth }
                                showTwoColumnMonthYearPicker={ isWholeMonth }
                                dateFormat= { isWholeMonth ? "MM/yyyy" : "MM/dd/yyyy"}
                            />
                        </div>
                    </label>
                }

                <label className="checkbox-label">
                    <input
                        type="checkbox"
                        checked={isWholeMonth}
                        onChange={(e)=> setIsWholeMonth(e.target.checked)}
                    />
                    Search whole month
                </label>
                <label className="centered-input">
                    <div className="wrapper">
                        
                        <input
                            type= "text"
                            onChange= {(e) => setSelectedPrice(e.target.value)}
                            className="input-box"
                            placeholder="Desired price"
                            name="price"
                            value={selectedPrice}
                        />
                    </div>
                </label>
                <label className="centered-input">
                    <div className="wrapper">
                        <SetAlertButton
                            price={selectedPrice} 
                            options={options}
                            isLoggedIn={isLoggedIn}
                            evilError={evilError}
                            monthPreferences={monthPreferences}
                            processedBuckets={processedBuckets}
                            isWholeMonth={ isWholeMonth }
                         />
                    </div>
                </label>


                </div>
            </form>

            { isWholeMonth && (
                <MonthCheckboxes
                    monthPreferences={ monthPreferences }
                    setMonthPreferences={ setMonthPreferences }
                />
            )}

            {/* once all relevant userInput fields have been filled out, wait for flightInfo to be populated and display results */}
            {loading && <p>Loading...</p>}
            {flightInfo.length > 0 && ( 
            <div className="flight-details"> 

                <div className="flight-text">
                    { completed && !isNaN(avgPrice) && <p> The average price for the {flightInfo.length} best flights is ${avgPrice} </p> }
                    { completed && minPrice !== Infinity && <p> The cheapest price is ${minPrice}</p> }
                </div>

                {/* if flightInfo array is populated, map the data to the InfoCard component */}
                {flightInfo.length > 0 ? <p className="fs--best-flights">Best Flights</p> : ''}
                {flightInfo.length > 0 ? 
                    flightInfo.map((data, i) => (
                    <div key={i}>
                            <InfoCard 
                                price= {data.price.formatted}
                                legs= {data.legs}
                                type= {type}
                            />

                    </div>
                    )) : ""
                }

                {/* {cheapestFlights.length > 0 ? <p className="fs--best-flights">Cheapest Flights</p> : ''}
                {cheapestFlights.length > 0 ? 
                    cheapestFlights.map((data, i) => (
                    <div key={i}>
                            <InfoCard 
                                price= {data.price.formatted}
                                legs= {data.legs}
                                type= {type}
                            />

                    </div>
                    )) : ""
                }

                {lilysCheapestFlights.length > 0 ? <p className="fs--best-flights">Lily's Cheapest Flights</p> : ''}
                {lilysCheapestFlights.length > 0 ? 
                    lilysCheapestFlights.map((data, i) => (
                    <div key={i}>
                            <InfoCard 
                                price= {data.price.formatted}
                                legs= {data.legs}
                                type= {type}
                            />

                    </div>
                    )) : ""
                } */}
            </div> )}
        </div>
    )

    }