import React from 'react'
import './../css/InfoCard.css'
import { FaArrowRight } from "react-icons/fa6";
import { IoArrowForward } from "react-icons/io5";
import { IoMdArrowDropright } from "react-icons/io";
import { IoMdArrowDropdown } from "react-icons/io";
import { GoArrowRight } from "react-icons/go";
import devLog from '../utilities/Devlog.js';

export default function InfoCard({price, legs, type}){

    const [isOpen, setIsOpen] = React.useState(false)
    const [overnight, setOvernight] = React.useState(false)

    // console.log("received legs", legs)
    // console.log(type)

    // departing flight
    const depAirport1 = legs[0].origin.name
    const depAirportID1 = legs[0].origin.id
    const destAirportID1 = legs[0].destination.id
    const destAirport1= legs[0].destination.name
    const airline1 = legs[0].carriers.marketing[0].name
    const depTime1 = legs[0].departure
    const arrTime1 = legs[0].arrival
    const totalDuration = legs[0].durationInMinutes
    const departureCityName = legs[0].origin.city
    const arrivalCityName = legs[0].destination.city
    const stopCount = legs[0].stopCount
    let logoUrl = legs[0].carriers.marketing[0].logoUrl
    let segments1 = []  // array to contain stops information

    let depAirport2 = ""
    let depAirportID2 = ""
    let destAirportID2 = ""
    let destAirport2= ""
    let airline2 = ""
    let depTime2 = ""
    let arrTime2 = ""
    let cityName2= ""

    if (legs[0].stopCount > 0) {
        segments1 = legs[0].segments.map((segment) => {
            return {
                departureAirportCode: segment.origin.displayCode,
                departureAirportName: segment.origin.parent.name,
                arrivalAirportCode: segment.destination.displayCode,
                arrivalAirportName: segment.destination.parent.name,
                departureTime: segment.departure,
                arrivalTime: segment.arrival,
                airline: segment.marketingCarrier.name,
                segmentDuration: segment.durationInMinutes
            }
        })
    }
    
    if (type === "Roundtrip" && legs[1]) {
        depAirport2 = legs[1].origin.name
        depAirportID2 = legs[1].origin.id
        destAirportID2 = legs[1].destination.id
        destAirport2= legs[1].destination.name
        airline2 = legs[1].carriers.marketing[0].name
        depTime2 = legs[1].departure
        arrTime2 = legs[1].arrival
        cityName2 = legs[1].destination.city
    }
    
    function toggleCollapse(){
        setIsOpen(!isOpen)
        // console.log(isOpen)
    }

    function formatDate(dateTimeString) {
        // Create a new Date object from the input string
        const date = new Date(dateTimeString);

        // Format the date part (month/day)
        const dateOptions = { month: 'numeric', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', dateOptions);

        // Combine and return the formatted date and time
        return `${formattedDate}`;
    }


    function formatTime(dateTimeString) {
        // Create a new Date object from the input string
        const date = new Date(dateTimeString);
    
        // Format the time part (hour:minute a.m./p.m.)
        const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
        const formattedTime = date.toLocaleTimeString('en-US', timeOptions);
    
        // Combine and return the formatted date and time
        return `${formattedTime}`;
    }

    function calculateFlightDuration(duration) {
        const hours = Math.floor(duration / 60)
        const minutes = Math.floor(duration % 60)
        return `${hours}h ${minutes}m`;

    }

    function calculateLayoverDuration(arrivalTime, departureTime) {
        const curArrival = new Date(arrivalTime)
        const nextDeparture = new Date(departureTime)
        const duration = nextDeparture - curArrival     //milliseconds

        // convert milliseconds to hours and minutes
        const hours = Math.floor(duration / (1000 * 60 * 60));
        const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));

        return `${hours}h ${minutes}m`;

    }


    
    return(
        <div className="infocard--container">
            <div className="infocard--header">
                <p> Departure {departureCityName} ({depAirportID1}) to {arrivalCityName} ({destAirportID1}) </p>
            </div>


            <div className="infocard--main leg-container">

                    <div className="infocard-col-1 col-1">
                        <p onClick={toggleCollapse}> {isOpen ? <IoMdArrowDropdown /> : <IoMdArrowDropright />} </p>
                        <p className="price-text">{price}</p>
                    </div>
                    <div className="infocard-col-2 col-2">
                        <p className="infocard--pre-text"> Times </p>
                        <p className="infocard--main-text">{ formatTime(depTime1) } <GoArrowRight /> { formatTime(arrTime1)} </p>
                        
                        <p className="infocard--sub-text"><span>{depAirportID1} to {destAirportID1}
                        {" "} {overnight && <p>| Overnight </p> }</span></p>
                        <div className="airline-img-container">
                            <img className="airline-img-small"
                                src={logoUrl} 
                                alt="Airline Logo"
                                onError={(e) => e.target.style.display = 'none'}
                            />
                            <p className="infocard--sub-text"> {airline1} Airlines </p>
                        </div>

                    </div>

                    <div className="infocard--airline-column">
                        <p className="infocard--pre-text">Airline</p>
                        <div className="airline-img-container">
                            <img className="airline-img-large"
                                src={logoUrl} 
                                alt="Airline Logo"
                                onError={(e) => e.target.style.display = 'none'}
                            />
                            <p className="infocard--main-text">{airline1}</p>
                        </div>

                    </div>

                    <div className="infocard-col-3 col-3">
                        <p className="infocard--pre-text">Flight duration</p>
                        <p className="infocard--main-text"> {calculateFlightDuration(totalDuration)}</p>
                        <p className="infocard--sub-text">
                            {stopCount > 0 && (
                                <p>{stopCount} {stopCount === 1 ? "stop" : "stops"}</p>
                            )}
                        </p>
                    </div>
                    <div className="infocard-col-4 col-4">
                        <p className="infocard--pre-text">Stops</p>
                        <p className="infocard--numStops infocard--main-text">
                            {stopCount > 0 ? (
                                    <p>{stopCount} {stopCount === 1 ? "stop" : "stops"}</p>
                            ) : <p>Direct</p>
                        }
                        </p>

                    </div>
            </div>

            {/* departureAirportCode: segment.origin.displayCode,
                    departureAirportName: segment.origin.parent.name,
                    arrivalAirportCode: segment.destination.displayCode,
                    arrivalAirportName: segment.destination.parent.name,
                    departureTime: segment.departure,
                    arrivalTime: segment.arrival,
                    airline: segment.marketingCarrier.name */}

                {isOpen && (
                    segments1.map((segment, i) => (
                    <div key={i}> 
                        <div className="leg-container">
                            <div className="infocard--col col-1"> 
                                <p className="infocard--leg"> Leg #<span className="leg-number-border">{i + 1} </span></p>
                            </div>
                            <div className="infocard--col col-2">
                                <p className="infocard--pre-text"> Times </p>
                                <p className="infocard--main-text">{ formatTime(segment.departureTime) } <GoArrowRight /> { formatTime(segment.arrivalTime)} </p>
                                <p className="infocard--sub-text">{segment.departureAirportCode} to {segment.arrivalAirportName} ({segment.arrivalAirportCode})</p>
                                <div className="airline-img-container">
                                    <img className="airline-img-small"
                                        src={logoUrl} 
                                        alt="Airline Logo" 
                                    />
                                    {" "}<p className="infocard--sub-text"> {segment.airline}</p>
                                 </div>
                            </div>

                            <div className="infocard--airline-column">
                                <p className="infocard--pre-text">Airline</p>
                                <div className="airline-img-container">
                                    <img className="airline-img-large"
                                        src={logoUrl} 
                                        alt="Airline Logo" 
                                    />
                                    {" "}<p className="infocard--main-text"> {segment.airline}</p>
                                 </div>
                                {/* <p className="infocard--main-text">{segment.airline}</p> */}
                            </div>
                            <div className="infocard--col col-3">
                            <p className="infocard--pre-text">Flight Duration</p>
                                <p className="infocard--main-text">{calculateFlightDuration(segment.segmentDuration)}</p>
                                {segments1[i+1] &&
                                    <p className="infocard--sub-text">{calculateLayoverDuration(segment.arrivalTime, segments1[i+1].departureTime)} layover</p>
                                }
                            </div>

                            <div className="infocard-col-4 col-4">
                                {segments1[i+1] &&
                                <div>
                                    <p className="infocard--pre-text">Layover duration</p>
                                    <p className="infocard--main-text">{calculateLayoverDuration(segment.arrivalTime, segments1[i+1].departureTime)}</p>
                                </div>
                                }

                    </div>
                        </div>
                        {/* {segments1[i+1]==null ? (
                            <div className="infocard--drop-shadow"></div>
                        ) : <div className="infocard--dashed-border"></div>} */}
                    </div>
                    ))
            )}
        </div>

    )
}