import axios from "axios"

async function ExactDateSearch({ departureId, destinationId, depDate, retDate, type, setOptions, numAdults, numInfants, cabinClass, numStops }){
    let options;
    // console.log("sanity check:", departureId, destinationId, depDate, retDate, type, "set options:", setOptions);

    // class option switch statement
    let cabinValue = 'invalid_default'
    if (cabinClass === "Economy"){
        cabinValue = "economy"
    }
    else if (cabinClass === "Premium Economy") {
        cabinValue = "premium_economy"
    }
    else if (cabinClass === "Business") {
        cabinValue = "business"
    }
    else if (cabinClass === "First") {
        cabinValue = "first"
    }

    // numStops switch statement
    let stopsValue = 'invalid_default'

    switch(numStops) {
        case 'Direct':
          stopsValue = 'direct'
          break;
        case '1 stop':
          stopsValue = '1stop'
          break;
        case '2+ stops':
            stopsValue = '2stops'
        default:
          stopsValue = 'Error or any stops'
      }
      

    if (type === 'Roundtrip') {
        // console.log("Roundtrip");
        options = {
            method: 'GET',
            url: 'https://sky-scanner3.p.rapidapi.com/flights/search-roundtrip',
            params: {
            fromEntityId: departureId,
            toEntityId: destinationId,
            departDate: depDate,
            returnDate: retDate,
            adults: numAdults,
            infants: numInfants,
            cabinClass: cabinValue,
            stops: stopsValue
            },
            headers: {
            'x-rapidapi-key': '7d2bbfcab8msh5ca0c5ea3ac9a0bp132895jsn445e4cb24826',
            'x-rapidapi-host': 'sky-scanner3.p.rapidapi.com'
            }
        }
    }
    else if (type === 'Oneway') {
        options = {
            method: 'GET',
            url: 'https://sky-scanner3.p.rapidapi.com/flights/search-one-way',
            params: {
            fromEntityId: departureId,
            toEntityId: destinationId,
            departDate: depDate,
            adults: numAdults,
            infants: numInfants,
            cabinClass: cabinValue,
            stops: stopsValue
            },
            headers: {
            'x-rapidapi-key': '7d2bbfcab8msh5ca0c5ea3ac9a0bp132895jsn445e4cb24826',
            'x-rapidapi-host': 'sky-scanner3.p.rapidapi.com'
            }
        }
    }
    else {
        // throw new Error("Unexpected trip type in roundtrip call.");
        // console.log("Error in ExactDateSearch");
    }

    // Log the options object to verify it's correctttt
    // console.log('Request options:', options);


    const response = await axios.request(options);
    // check if response is successful, otherwise throw error
    if (setOptions) {
        setOptions(options)
    }
    return response.data

}

export default ExactDateSearch