import React, {useState} from 'react'
// import '../css/LoginSignup.css'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from './Header.js';

const SignupForm = () => {
    // State for form fields
    const [formData, setFormData] = useState({
      username: '',
      email: '',
      password: ''
    });
    const [error, setError] = useState();
    const navigate = useNavigate();
  
    // Handle form field changes
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    // Handle form submission
    async function handleSubmit(e) {
      e.preventDefault();
      try {
        // Make a POST request to your backend API to authenticate the user
        const signupResponse = await axios.post(`${process.env.REACT_APP_API_URL}/users/signup`, {
          username: formData.username,
          password: formData.password,
          email: formData.email
        },
        {withCredentials: true})

        // console.log(signupResponse)
        setError(signupResponse.data.message)

        if (signupResponse.data.success) {
          const loginResponse = await axios.post(`${process.env.REACT_APP_API_URL}/users/login`, { 
            username: formData.username, password: formData.password
           },
          {withCredentials: true})

          if (loginResponse.data.success == true) {
            // console.log("sanity check: logged in after signing up")
            localStorage.setItem('token', loginResponse.data.token)
            navigate('/')
          }
          else{
            // console.log("error signing in after signing up");
          }
        }
      } catch (error) {
        console.error('Error signing up:', error);
      }
    };
  
    return (
      <div>
            <div className="login--image-container">
                <img className="header--image" src="./sky2.jpg" alt='Sky image background'/>

                <div className="login--form-container">
                  <form onSubmit={handleSubmit}>
                  <p className="login--main-text">Sign Up with Autopilot</p>
                    <div className="form-items">
                    { error && <p className="form-error"> {error} </p> }
                      <label htmlFor="username">
                      <input
                        placeholder="Username"
                        className="text-box"
                        type="text"
                        id="username"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        required
                      />
                      </label>
                    </div>
                    <div>
                      <label htmlFor="email">
                      <input
                        placeholder="Email"
                        className="text-box"
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                      </label>
                    </div>
                    <div>
                      <label htmlFor="password">
                      <input
                        placeholder="Password"
                        className="text-box"
                        type="password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                      />
                      </label>
                    </div>
          <button className="form--button" type="submit">sign up</button>
          <p className="bottom-text"> Already have an account? Log in <a href={`${process.env.REACT_APP_CLIENT_URL}/login`}> here </a></p>
        </form>
      </div>
                </div>
            </div>
    );
  };
  
  export default SignupForm;