import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';
// import './../css/Header.css'
import './../css/HeaderRed.css'
import Navbar from "./Navbar.js";
import devlog from "../utilities/Devlog.js";


export default function Header(){

        
    return(
        <div className="image-wrapper"> 
            <div className="image-container">
                {/* <img className="header--image" src="./sky2.jpg" alt='Sky image background'/> */}
                <img className="header--image" src="./plane_red.jpg" alt='Sky image background'/>
                <div className="tab-wrapper">
                  <div className="tab tab-1">Flights</div>
                  <div className="tab tab-2">Hotels</div>
                  <div className="tab tab-background"></div>

                </div>
                {/* <Navbar
                    user = {user}
                    setToken= {setToken}
                    setUser = {setUser}
                    setIsloggedIn = {setIsLoggedIn}
                /> */}
                <div className="dark-overlay"></div>
                <div className="text-overlay">
                    <p className="header--title"> Alerts On Autopilot </p>
                    <p className="header--subtitle"> Tailored alerts for flights at your price point </p>
                </div>
            </div>
        </div>
    )
}

