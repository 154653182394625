import '../css/MonthCheckboxes.css'

export default function MonthCheckboxes( {monthPreferences, setMonthPreferences} ){
    return (
        <div className="month-container">
            <p>I'm interested in...</p>
            <label className="month-label"> 
                <input 
                    type="checkbox"
                    checked={monthPreferences.weekendTrips}
                    onChange={(e)=> setMonthPreferences({ ...monthPreferences, weekendTrips: (e.target.checked)})}
                /> Weekend trips
            </label>
            <label className="month-label"> 
                <input
                    type="checkbox"
                    checked={monthPreferences.threeFourDayTrips}
                    onChange={(e)=> setMonthPreferences({ ...monthPreferences, threeFourDayTrips: (e.target.checked)})}
                /> 3-4 day trips
            </label>
            <label className="month-label"> 
                <input
                    type="checkbox"
                    checked={monthPreferences.oneWeekTrips}
                    onChange={(e)=> setMonthPreferences({ ...monthPreferences, oneWeekTrips: (e.target.checked)})}
                /> 1 week trips
            </label>
            <label className="month-label"> 
                <input
                    type="checkbox"
                    checked={monthPreferences.onePlusWeekTrips}
                    onChange={(e)=> setMonthPreferences({ ...monthPreferences, onePlusWeekTrips: (e.target.checked)})}
                /> 1+ week trips
            </label>
        </div>
    )
}