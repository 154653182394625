import React, { useState, useEffect } from 'react';
import '../css/SetAlertButton.css'
import devlog from '../utilities/Devlog.js';


export default function SetAlertButton({ price, options, isLoggedIn, evilError, monthPreferences, isWholeMonth }) {
    const [isUpdating, setIsUpdating] = useState(false);
    const [notif, setNotif] = useState(null)
    const [fieldError, setFieldError] = useState({
        allFields: null,
        noTripSelected: null,
    })

    devlog("options:", options)

    const handleUpdateClick = async () => {

        /* grab logged in user */
        const token = localStorage.getItem('token')

        if (!token) {
            console.error('No token found');
            // please log in error
            return;
        }

        if (!options) {
            setFieldError(prev => ({
                ...prev,
                allFields: "Please fill out all fields"
            }))
            // setIsUpdating(false)
            // // devlog("no url");
            devlog("no options")
            return;
        }

        if (evilError) {
            // // devlog("evil error");
            setIsUpdating(false)
            return;
        }

        if (isWholeMonth) {
            // devlog(isWholeMonth);
            if (monthPreferences.weekendTrips || monthPreferences.threeFourDayTrips || monthPreferences.oneWeekTrips || monthPreferences.onePlusWeekTrips) {
                monthPreferences.set = true;
                setFieldError(prev => ({
                    ...prev,
                     noTripSelected: null 
                }) 
            )
                // devlog("month preferences set!");
            }
            else {
                setFieldError(prev => ({
                    ...prev,
                    noTripSelected: "Please select a trip type"
                }))
                return;
            }
        }

        const updatedData = {
            price: price,
            options: options,
            setAlert: true,
            monthPreferences: monthPreferences,
        };


        // devlog("tripSelected:",  fieldError.noTripSelected);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/updateUser`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify(updatedData)
            });

            if (response.ok) {
                const data = await response.json();
                // devlog("Data set!", data);
                setNotif("Alert set! An email will be sent to you if a flight matches your specficiations. Flights are checked every day.")
            } else {
                console.error('Failed to update user in SetAlertButton.js');
            }
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };



    return (
        <div>
            { isLoggedIn && (
                <button className="alert-button" onClick={handleUpdateClick} disabled={isUpdating}>
                    set alert
                </button>
            )}
            {notif && (
                <div className="notif success-notif">
                    <span className="checkmark">✔️</span>
                    <span>{notif}</span>
                </div>
            )}
        </div>
    );
}
