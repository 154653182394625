import devlog from "./Devlog.js";
import axios from 'axios'

async function FetchIncompleteDates({ flightData, numStops }){


    let isComplete = false;
    let aggregatedData = [];
    let stopsValue = 'invalid_default'

    switch(numStops) {
        case 'Direct':
          stopsValue = 'direct'
          break;
        case '1 stop':
          stopsValue = '1stop'
          break;
        case '2+ stops':
            stopsValue = '2stops'
        default:
          stopsValue = ''
      }
    //   console.log("numStops in Fetch Incomplete", stopsValue)

    if (flightData.data.context.status === "complete"){
        aggregatedData = aggregatedData.concat(flightData.data.itineraries)
        //devlog("aggregatedData in api:", aggregatedData)
        // console.table("roundtrip itineraries:", aggregatedData).
        isComplete = true;
        return aggregatedData
    }

    let sessionId = flightData.data.context.sessionId


    // wait for all flights to be fetched
    while (!isComplete) {
        // console.table("flightData " + flightData)
        //devlog("while loop still running...");

        const options_ = {
            method: 'GET',
            url: 'https://sky-scanner3.p.rapidapi.com/flights/search-incomplete',
            headers: {
              'x-rapidapi-key': '7d2bbfcab8msh5ca0c5ea3ac9a0bp132895jsn445e4cb24826',
              'x-rapidapi-host': 'sky-scanner3.p.rapidapi.com'
            },
            params: {
                sessionId: sessionId,
                stops: stopsValue
            }
        }

        // returns more flights for the roundtrip query
        const statusResponse = await axios.request(options_)
        const statusData = statusResponse.data

        // add new flight itineraries to aggregatedData array
        if (statusData.data && statusData.data.itineraries) {
            aggregatedData = aggregatedData.concat(statusData.data.itineraries);
        }

        if (statusData.data.context.status === 'complete') {
            isComplete = true;
            devlog("status is complete!");
            return aggregatedData
        }

        if (!isComplete) {
            await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second/
        }
    }
}

export default FetchIncompleteDates
